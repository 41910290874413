import React, { useState, useEffect, useRef } from "react";
import { Notifications } from "./Notifications";
import BellIcon from "../../images/bellIcon.svg";
import { useSelector } from "react-redux";

function useOutsideAlerter(ref, setDropdownOpen) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        //   alert("You clicked outside of me!");
        setDropdownOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const NotificationDropdown = () => {
  console.log("locationm", window.location.pathname);
  const notifications = useSelector(
    (state) => state.primaryEmail.notifications.listOfNotifications
  );
  const [isDropdownOpend, setDropdownOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setDropdownOpen);
  const [isOpen, setIsOpen] = useState(false);

  const closePopup = () => {
    setIsOpen(false);
  };
  const openPopup = () => {
    setIsOpen(true);
  };

  const toggleDropDown = () => setDropdownOpen(!isDropdownOpend);

  return (
    <>
      {isOpen && (
        <div className="notification-popup-overlay" onClick={closePopup}>
          <div className="notification-popup-content">
            <p
              style={{
                fontWeight: "700",
                lineHeight: "32px",
                textAlign: "center",
                fontSize: "24px",
                color: "#A09FA0",
              }}
            >
              OOPS!
            </p>
            <p
              style={{
                fontWeight: "400",
                lineHeight: "21px",
                textAlign: "center",
                fontSize: "16px",
                color: "#3C4852",
              }}
            >
              We are unable to redirect you to this content because <br></br>{" "}
              your course subscription has expired.
            </p>
            <div style={{ paddingBottom: "24px" }}>
              <img
                src="https://webcdn.imarticus.org/Fsd/Frame.svg"
                alt="Course Expired"
              />
            </div>
            <button style={{ width: "160px" }} onClick={closePopup}>
              Okay
            </button>
          </div>
        </div>
      )}
      <div ref={wrapperRef} style={{ display: "flex" }}>
        <button
          onMouseEnter={() => setDropdownOpen(true)}
          onClick={toggleDropDown}
          style={{ border: "none", background: "transparent", outline: "none" }}
        >
          <img
            src={
              "https://cdn.pegasus.imarticus.org/index/notifications_neutral.svg"
            }
            alt="NotificationIcon"
            style={{
              backgroundColor: isDropdownOpend
                ? "rgba(60,72,82,0.10)"
                : "white",
              borderRadius: "8px",
            }}
          />
          {notifications && countUnreadNots(notifications) !== 0 && (
            <span
              className="badge badge-danger"
              style={{ fontSize: "75% !important" }}
            >
              {notifications && countUnreadNots(notifications)}
            </span>
          )}
        </button>
        {isDropdownOpend && (
          <div
            className="user-notification-menu"
            style={{
              right: window.location.pathname.includes("/gradebook")
                ? "-30px"
                : "0px",
            }}
          >
            <Notifications seeAll={true} openPopup={openPopup} />
          </div>
        )}
      </div>
    </>
  );
};
export default NotificationDropdown;

const countUnreadNots = (notifications) => {
  return notifications.filter((d) => d.status && d.status == true).length;
};
