import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { CONFIGS } from "../../constants/constants";
import { utils } from "../../utility/utils";
import { Redirect } from "react-router";
import imarticuLogo from "../../images/ImarticusLogo.png";
import Loader from "../utils/Loader";
const styles = {
  markRead: {
    fontWeight: " 600",
    fontSize: " 14px",
    lineHeight: " 32px",
    color: "#055646",
  },
  notRowDiv: {
    // minHeight: "98px !important",
    padding: "16px 0px",
    borderBottom: "1px solid rgba(60, 72, 82, 0.25)",
  },
  text: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#212A39",
  },
  course: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#055646",
  },
  timeSince: {
    float: "right",
  },
  disabled: {
    opacity: "0.5",
  },
  cursPoint: {
    cursor: "pointer",
  },
};

const Notifications = ({ seeAll, openPopup }) => {
  const notifications = useSelector(
    (state) => state.primaryEmail.notifications.listOfNotifications
  );
  // const courses = useSelector((state)=> state.course.courses )
  // const [nots, setNots] = useState(notifications || []);
  const [ifAllRead, setIfAllRead] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log({
      notifications,
    });
    console.log(
      notifications &&
        notifications.filter((d) => d && d.status == true).length,
      "------------------------------"
    );
    if (
      notifications &&
      notifications.filter((d) => d && d.status == true).length > 0
    ) {
      setIfAllRead(false);
    }
  }, [notifications]);
  const markAllAsRead = () => {
    // setLoading(true);
    console.log(
      " ============================ In Mark All As Seen ============================== "
    );
    if (ifAllRead) return;
    axios
      .post(`${CONFIGS.API_API_ENDPOINT}notifications/mrk_al_as_rd`, {
        aid: utils.getAid(),
        tokn: utils.getAt(),
      })
      .then((res) => {
        setIfAllRead(true);
        notifications.forEach((element) => {
          element.status = false;
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  console.log({ notificationsInRedux: notifications, ifAllRead });
  if (loading) return <Loader />;
  return (
    <>
      {/* <div className="group-db-content-wrapper">
        <div className="container-fluid dashboard-content">
          <div className=" profile-setting-container-row">
            <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="row justify-content-center"> */}
      <div
        className="notifications-main-content"
        style={{ fontFamily: "Source Sans Pro" }}
      >
        <div style={{ position: "sticky" }}>
          <p
            className="edit-profile-heading"
            style={{ fontSize: "20px", paddingBottom: "0px" }}
          >
            Notifications{" "}
            {seeAll && notifications && notifications.length > 0 && (
              <span
                style={{
                  float: "right",
                  ...styles.markRead,
                  // ...(ifAllRead && styles.disabled),
                }}
                onClick={() =>
                  markAllAsRead()(
                    (window.location.href = "/dashboard/notifications")
                  )
                }
              >
                View All
              </span>
            )}
            {/* {notifications && notifications.length > 0
              ? notifications.length - 1 > 10
              ? `(${notifications.length - 1})`
              : `(0${notifications.length - 1})`
            : `(0)`} */}
            <hr />
          </p>
        </div>
        <div className="edit-window-profile-details">
          {/* <span
            style={{
              ...styles.markRead,
              ...styles.cursPoint,
              ...(ifAllRead && styles.disabled),
            }}
            onClick={() => {
              setLoading(true);
              markAllAsRead();
            }}
          >
            <b>Mark all as read</b>
          </span> */}
          {/* <div style={{ marginBottom: "20px" }}>
          {/* {seeAll && notifications && notifications.length > 0 && (
              <span
                style={{
                  float: "right",
                  ...styles.markRead,
                  ...styles.cursPoint,
                  // ...(ifAllRead && styles.disabled),
                }}
                onClick={() =>
                  (window.location.href = "/dashboard/notifications")
                }
              >
                View All
              </span>
            )} 
          </div> */}
          {notifications ? (
            notifications.length > 0 ? (
              notifications.map((n, idx) => (
                <div
                  key={idx}
                  style={{
                    ...styles.notRowDiv,
                    ...styles.cursPoint,
                    display: "flex",
                    flexDirection: "row",
                  }}
                  onClick={() => {
                    console.log(n);
                    if (n.expired) {
                      openPopup();
                    } else {
                      redirectToCourse(n, markAllAsRead);
                    }
                  }}
                >
                  <div
                    style={{ float: "left", maxWidth: !seeAll ? "15%" : "30%" }}
                  >
                    <img
                      style={{
                        width: !seeAll ? "60px" : "74px",
                        height: !seeAll ? "50px" : "50px",
                        borderRadius: "4px",
                      }}
                      src={n.crs_thumb_url || imarticuLogo}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingLeft: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        // marginLeft: "auto",
                        ...styles.text,
                        ...(!n.status ? styles.disabled : {}),
                      }}
                    >
                      {n.toRenderText && !seeAll
                        ? n.toRenderText
                        : n.toRenderText.length > 78
                        ? n.toRenderText.slice(0, 78) + "..."
                        : n.toRenderText}
                    </p>
                    <div
                      style={{
                        marginTop: "auto",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          ...styles.course,
                          ...(!n.status ? styles.disabled : {}),
                        }}
                      >
                        {n.courseName && !seeAll
                          ? n.courseName
                          : n.courseName.length > 35
                          ? n.courseName.slice(0, 35) + "..."
                          : n.courseName}
                      </span>
                      <span
                        style={{
                          ...styles.timeSince,
                          ...(!n.status ? styles.disabled : {}),
                        }}
                      >
                        {timeSince(n.createdAt)}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>You have no notifications currently! Try again later maybe?</p>
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};

function redirectToCourse(not, markAllAsRead) {
  markAllAsRead();
  console.log(" In redirectToCourse with : --- ");
  if (not.level_labels && not.level_labels.length === 1) {
    let gid = not.gids ? not.gids[0] : null;
    console.log({ pid: utils.getPid(), gid, cid: not.cid });
    let url =
      "/batch" +
      "/" +
      utils.getPid() +
      "/" +
      gid +
      "/" +
      not.cid +
      "/undefined";
    console.log({ url });
    window.location.replace(url);
    return;
  } else {
    return <Redirect to={"/view/?cid=" + not.cid} />;
  }
}

function timeSince(date) {
  date = new Date(date);
  var seconds = Math.floor((new Date() - date) / 1000);
  const Lday = date.toLocaleString("en-US", { weekday: "long" });
  const Ldate = date.toLocaleDateString();
  const Ltime = date.toLocaleTimeString();

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Ldate;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Ldate;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    if (interval < 3) {
      let intervalleft = seconds - Math.floor(interval) * 86400;
      intervalleft = intervalleft / 3600;
      return Lday + "  " + Ltime;
    }
    return Ldate;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return (
      Math.floor(interval) +
      " hour" +
      (Math.floor(interval) > 1 ? "s" : "") +
      " ago"
    );
  }
  interval = seconds / 60;
  if (interval > 1) {
    return (
      Math.floor(interval) +
      " minute" +
      (Math.floor(interval) > 1 ? "s" : "") +
      " ago"
    );
  }
  return (
    Math.floor(seconds) +
    " second" +
    (Math.floor(interval) > 1 ? "s" : "") +
    " ago"
  );
}

const NotificationHelper = () => {
  return (
    <div className="group-db-content-wrapper">
      <div className="container-fluid dashboard-content">
        <div className=" profile-setting-container-row">
          <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            {/* <div className="row justify-content-center">  */}
            <Notifications />
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export { NotificationHelper, Notifications };
